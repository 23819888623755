import { LitElement, html } from 'lit';
import { stylesFooter } from './index.styles.js';
import Logo from "../../assets/logo_hf.svg";
import Copy from "../../assets/copy.svg";
import "./../Icons/IconImg/index.js";

class Footer extends LitElement {
  static styles = stylesFooter;

  render() {
    return html`
      <div class="container-footer">
        <icon-img-component 
        .icon=${Logo}
        .iconStyle=${"md"}
        .description="Logo Hi Fenix"
        >
        </icon-img-component>
        <div class="container-right"> 
          <icon-img-component 
          .icon=${Copy}
          .iconStyle=${"sm"}
          .description="Logo Hi Fenix"
          >
          </icon-img-component> 
          <p class="text-footer">
            Todos los derechos reservados
          </p>
        </div>
      </div>
    `;
  }
}

customElements.define('footer-component', Footer);
