import { LitElement, html } from "lit";
import { stylesLayout } from "./index.styles.js";

class LayoutComponent extends LitElement {
  static styles = stylesLayout;

  render() {
    return html`
      <div class="layout-container">
        <!-- Header -->
        <div class="header-container">
          <slot name="header"></slot>
        </div>

        <!-- Main Chat -->
        <div class="main-chat-container">
          <slot name="main-chat"></slot>
        </div>

        <!-- Footer -->
        <div class="footer-container">
          <slot name="footer"></slot>
        </div>
      </div>
    `;
  }
}

customElements.define("layout-component", LayoutComponent);
