import { LitElement, html } from "lit";
import { stylesMainChatLayout } from "./index.styles.js";
import "./messagesBody/index.js"; // Importar directamente MessagesBody
import "./inputMessage/index.js"; // Importar directamente InputMessage

class MainChatLayout extends LitElement {
  static styles = stylesMainChatLayout;

  static properties = {
    messages: { type: Array }, // Lista de mensajes para MessagesBody
    onSendMessage: { type: Function }, // Callback para manejar el envío de mensajes desde InputMessage
    isDragging: { type: Boolean },
    startY: { type: Number },
    scrollTopStart: { type: Number },
  };

  constructor() {
    super();
    this.messages = []; // Lista inicial de mensajes
    this.onSendMessage = (message) => {
      console.log("Mensaje enviado:", message); // Por defecto, muestra en la consola
    };
    this.isDragging = false;
    this.startY = 0;
    this.scrollTopStart = 0;
  }

  // Método para hacer scroll al final del contenedor de mensajes
    _scrollToBottom() {
      const container = this.renderRoot.querySelector(".message-body-container");
      if (container) {
        // Usamos requestAnimationFrame para asegurar que el DOM está actualizado
        requestAnimationFrame(() => {
          container.scrollTo({
            top: container.scrollHeight,
            behavior:"smooth",
          });
        });
      }
    }
    // Se asegura de hacer scroll cuando los mensajes cambian
    updated(changedProperties) {
      if (changedProperties.has("messages")) {
        this._scrollToBottom();
      }
    }
  //Funciones para manejar el scroll al hacer click
    // Método para manejar el inicio del drag
  _onMouseDown(event) {
    this.isDragging = true;
    this.startY = event.clientY;
    const container = this.renderRoot.querySelector(".message-body-container");
    this.scrollTopStart = container.scrollTop;
    event.preventDefault(); // Previene selección no deseada de texto
  }
  // Método para manejar el movimiento del mouse
  _onMouseMove(event) {
    if (!this.isDragging) return;
    const container = this.renderRoot.querySelector(".message-body-container");
    const deltaY = event.clientY - this.startY; // Diferencia de posición del mouse
    container.scrollTop = this.scrollTopStart - deltaY; // Ajusta la posición del scroll
  }
  // Método para manejar el final del drag
  _onMouseUp() {
    this.isDragging = false;
  }

  render() {
    return html`
      <div class="main-chat-layout">
        <!-- Message Body -->
        <div class="message-body-container" 
        >
          <messages-body-component
            .messages=${this.messages}
          ></messages-body-component>
        </div>

        <!-- Input Message -->
        <div class="input-message-container">
          <input-message-component
            .submit=${this.onSendMessage}
          ></input-message-component>
        </div>
      </div>
    `;
  }
}

customElements.define("main-chat-layout", MainChatLayout);
