import { css } from "lit";

export const styleModalConfirmation = css`
  .modal-container {
    display: flex;
    position: absolute;
    top: 138px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
    width: 100%;
    max-width: 416px;
    height: 187px;
    padding: 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;
    border-radius: 16px;
    background: var(--Fondo_sidebar, #fafafa);
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    user-select: none; /* Bloquea selección de texto */
    -webkit-user-select: none; /* Para compatibilidad Safari iOS */
  }
  @media screen and (max-width: 500px) {
    .modal-container {
      width: 95%;
    }
  }

  .modal-title {
    align-self: stretch;
    color: var(--Gray-1, #333);
    text-align: center;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 20px */
    margin: 0;
  }

  .modal-subtitle {
    align-self: stretch;
    color: var(--Gray-3, #828282);
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
    margin: 0;
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: stretch;
  }

  .btn-cancelar {
    border-radius: 100px;
    border: 1px solid var(--Azul_chatbot, #1446ff);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    width: 150px;
    height: 40px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: transparent;
    cursor: pointer;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    color: var(--Azul_chatbot, #1446ff);
  }

  .btn-reiniciar {
    border-radius: 100px;
    background: var(--Azul_chatbot, #1446ff);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    width: 149px;
    height: 40px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    border: none;
  }
`;
