import { LitElement, html } from 'lit';
import { styleInputMessage } from './index.styles.js';
import PaperPlane from "../../../assets/paper_plane.svg";

class InputMessage extends LitElement {
  static properties = {
    message: { type: String, state: true },
    submit: { type: Function },
  };

  static styles = styleInputMessage;

  constructor() {
    super();
    this.message = '';
    this.submit = async () => {};
  }

  _handleInputChange(event) {
    this.message = event.target.value;
  }

  async _handleSubmit() {
    if (this.submit && typeof this.submit === 'function' && this.message.trim().length > 0) {
      try {
        const messageUser = this.message;
        this.message = '';
        await this.submit(messageUser);
      } catch (err) {
        console.error("Problemas al comunicarse con el chat-bot: ", err?.message);
      }
    }
  }

  _handleKeyPress(event) {
    if (event.key === 'Enter') {
      this._handleSubmit();
    }
  }

  render() {
    return html`
      <div class="input-message-container">
        <input 
          type="text" 
          class="input-message"
          .value=${this.message} 
          @input=${this._handleInputChange} 
          @keypress=${this._handleKeyPress} 
          placeholder="Escribe tu mensaje..."
        />
        <button 
          @click=${this._handleSubmit} 
          class="button-send"
          aria-label="Enviar mensaje"
          title="Enviar mensaje"
          .disabled=${this.message? false : true}
        >
          <img class="icon-send" src=${PaperPlane} alt="Icono de enviar" />
        </button>
      </div>
    `;
  }
}

customElements.define('input-message-component', InputMessage);
