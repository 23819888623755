import { LitElement, html } from "lit";
import { styleHeader } from "./index.styles.js";

import ChatImg from "../../assets/chatbot.svg";
import ArrowDown from "../../assets/arrow_down.svg";
import RefreshIcon from "../../assets/refresh_icon.svg";

// Logos/títulos especiales
import GeniLogo from "../../assets/Geni_logo.svg";
import GeniTitle from "../../assets/Geni_title.svg";

import "../Icons/IconBtn/index.js";
import "../Icons/IconImg/index.js";
import "../ModalConfirmation/index.js";

const geniLogoUrl =
  "https://cdn.jsdelivr.net/npm/hifenix-chatbot@1.0.11/dist/assets/genilogo.svg";

class Header extends LitElement {
  static properties = {
    closeChat: { type: Function },
    showModal: { type: Boolean },
    onResetChat: { type: Function },

    // Nueva prop para decidir si es geni
    companyName: { type: String },
  };

  static styles = styleHeader;

  constructor() {
    super();
    this.closeChat = () => {};
    this.showModal = false;
    this.onResetChat = () => {};
    this.companyName = ""; // Por defecto vacío
  }

  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  render() {
    // 1) Verificamos si la prop companyName es "geni"
    const isGeni = this.companyName.toLowerCase() === "geni";

    // 2) Si es geni, usamos GeniLogo; si no, ChatImg
    const iconToUse = isGeni ? geniLogoUrl : ChatImg;

    // 3) Para el título principal:
    //    - Si es geni, usamos <img src="Geni_title.svg" ...>
    //    - Si no, mantenemos el h2
    const mainTitle = isGeni
      ? html`
          <img src=${GeniTitle} alt="Geni Title" class="geni-title-img" />
        `
      : html`
          <h2 class="header-title">
            <span class="header-title-span">HI CHAT</span> ASSISTANT
          </h2>
        `;

    return html`
      <section class="header-container">
        <!-- Ícono principal -->
        <div class="icon-container">
          <icon-img-component
            .iconStyle=${"profile"}
            .icon=${iconToUse}
            .description=${"Icono de enviar"}
          >
          </icon-img-component>
        </div>

        <!-- Texto: título y subtítulo (solo modificamos el título si es isGeni) -->
        <div class="container-text">
          ${mainTitle}
          <p class="header-sub-title">¡Hola! 👋 Estoy aquí para ayudarte</p>
        </div>

        <!-- Botón de refrescar -->
        <icon-btn-component
          class="header-button-refresh"
          .styleImg=${"default"}
          .icon=${RefreshIcon}
          .description=${"Reiniciar conversación"}
          .styleBtn=${"button-refresh"}
          .callback=${() => this.openModal()}
        >
        </icon-btn-component>

        <!-- Botón para cerrar el chat -->
        <icon-btn-component
          class="header-button-position"
          .styleImg=${"default"}
          .icon=${ArrowDown}
          .description=${"Cerrar Hi chat Bot"}
          .styleBtn=${"button-close"}
          .callback=${this.closeChat}
        >
        </icon-btn-component>
      </section>

      <!-- Modal de confirmación (reiniciar) -->
      ${this.showModal
        ? html`
            <modal-confirmation
              .cancelarModal=${() => this.closeModal()}
              .reiniciarConversacion=${() => {
                this.onResetChat();
                this.closeModal();
              }}
            ></modal-confirmation>
          `
        : null}
    `;
  }
}

customElements.define("header-component", Header);
