import { LitElement, html } from 'lit';
import { stylesMessagesBody } from './index.styles.js'; // Importa las clases CSS como módulo
import "./message/index.js"

class MessagesBody extends LitElement {
  /** Documentacion de tipado
 * @typedef {Object} Message
 * @property {String} images - La imagen del producto.
 * @property {string} message - El contenido del mensaje.
 * @property {string} userType - El tipo de usuario.
 * @property {boolean} loading - Tipo de carga del mensaje.
 */
  /**
 * @type {{ messages: Array<Message> }}
  */
  static properties = {
    messages: { type: Array },
  };

  // Define los estilos
  static styles = stylesMessagesBody;

  constructor() {
    super();
    this.messages = [];
  }
  render() {
    const messages = this.messages || []; 
    return html`
      <div class="messages-body-container">
      ${messages[0] && messages.map(message => {
         return html`
            <div 
              class="message-container ${message?.userType}">
              <message-component 
                .images=${message?.images}
                .userType=${message?.userType} 
                .message=${message?.text}
                .loading=${message?.loading}>
              </message-component>
            </div>
         `
        }) 
      }  
      </div>
    `;
  }
}

customElements.define('messages-body-component', MessagesBody);
