import { css } from "lit";

export const stylesMainChatLayout = css`
  .main-chat-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Evita desbordes */
    box-sizing: border-box;
  }

  .message-body-container {
    max-width: 100%;
    height: 361px;
    max-height: 361px;
    box-sizing: border-box;
    overflow-y: auto; /* Habilita scroll si el contenido excede */
    background: #fff; /* Fondo blanco */
    scrollbar-width: thin; /* Scroll fino en Firefox */
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent; /* Colores en Firefox */
  }

  .input-message-container {
    max-width: 416px;
    height: 61px;
    max-height: 61px;
    flex-shrink: 0;
    background: #fff; /* Fondo blanco */
    border-top: 0.5px solid var(--Gray-5, #E0E0E0);
    border-bottom: 0.5px solid var(--Gray-5, #E0E0E0);
    box-sizing: border-box;
  }`;
 