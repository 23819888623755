import { io } from "socket.io-client";
import { jwtDecode } from "jwt-decode"; // Usar la importación con nombre

let socket = null;

export const initializeSocket = (companyToken) => {
  try {
    // Decodificar el token y obtener urlSocket
    const decodedToken = jwtDecode(companyToken);
    let URL_BACK_SOCKET = decodedToken?.urlSocket;

    if (!URL_BACK_SOCKET) {
      throw new Error("URL del socket no encontrada en el token.");
    }

    // Analizar la URL completa
    const urlParts = new URL(URL_BACK_SOCKET);

    // La base URL incluye todos los prefijos antes de `socket.ALGO`
    const baseUrl = `${urlParts.protocol}//${
      urlParts.host
    }${urlParts.pathname.replace(/\/socket\.\w+$/, "")}`;

    // Extraer el path que comienza con `socket.ALGO`
    const pathMatch = urlParts.pathname.match(/\/(socket\.\w+)$/);
    const socketPath = pathMatch ? `/${pathMatch[1]}` : null;

    if (!socketPath) {
      throw new Error("Path del socket no encontrado o inválido.");
    }

    // Configurar el socket con la base URL y el path correcto
    socket = io(baseUrl, {
      path: socketPath, // Path dinámico obtenido
      transports: ["websocket"],
      autoConnect: true,
      reconnectionAttempts: 3,
      reconnectionDelay: 2000,
    });

    socket.on("connect", () => {
      console.log("Conectado al servidor con éxito.");
    });

    socket.on("disconnect", (reason) => {
      console.log("Desconectado del servidor:", reason);
    });

    socket.on("reconnect_attempt", (attemptNumber) => {
      console.log(`Intento de reconexión automática #${attemptNumber}`);
    });

    socket.on("reconnect", () => {
      console.log("Reconexión automática exitosa al servidor.");
    });

    window.socket = socket; // Hacer el socket accesible globalmente
  } catch (error) {
    console.error("Error al inicializar el socket:", error);
  }
};

/**
 * Obtiene un conversationId desde localStorage si no han pasado 30 min.
 * Si no existe o ya caducó, genera uno nuevo.
 */
export const getOrCreateConversationId = () => {
  // Leer el ID y el timestamp
  const existingConversationId = localStorage.getItem("conversationId");
  const existingTimestamp = localStorage.getItem("conversationTimestamp");

  // Si hay ID y timestamp, verificamos cuánto tiempo pasó
  if (existingConversationId && existingTimestamp) {
    const createdAt = parseInt(existingTimestamp, 10);
    const now = Date.now();
    const THIRTY_MINUTES = 60 * 60 * 1000; // 3600000 ms

    // Si han pasado menos de 60 min, reusamos el ID
    if (now - createdAt < THIRTY_MINUTES) {
      return existingConversationId;
    }
  }

  // Si no hay ID/timestamp o ya caducó, creamos uno nuevo
  const newConversationId = crypto.randomUUID();
  localStorage.setItem("conversationId", newConversationId);
  localStorage.setItem("conversationTimestamp", Date.now().toString());

  return newConversationId;
};

/**
 * Borra el conversationId anterior, genera uno nuevo y avisa al socket.
 * Retorna el nuevo conversationId.
 */
export const resetConversationId = (companyToken) => {
  // 1. Borrar el ID anterior
  localStorage.removeItem("conversationId");
  localStorage.removeItem("conversationTimestamp");
  console.log("Conversación reiniciada");

  // Generar uno nuevo
  const newConversationId = crypto.randomUUID();
  localStorage.setItem("conversationId", newConversationId);
  localStorage.setItem("conversationTimestamp", Date.now().toString());

  // Avisar al socket si está conectado
  if (window.socket && window.socket.connected) {
    window.socket.emit("reconnect", {
      companyToken,
      conversationId: newConversationId,
    });
  }

  return newConversationId;
};
