import { css } from "lit";

export const styleInputMessage = css`
  .input-message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .input-message {
    box-sizing: border-box;
    padding: 18px 6px 18px 24px;
    border: 0px;
    width: 100%;
    color: black;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    outline: none;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  .input-message:focus {
    border: 0.5px solid var(--Azul_chatbot, #1446ff); /* Cambia el borde */
    background-color: white; /* Cambia el fondo */
  }
  .button-send {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px;
    margin: 6px 18px;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    max-width: 50px;
    max-height: 50px;
    flex-shrink: 0;
    background-color: var(--Azul_chatbot, #1446ff);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    user-select: none; /* Bloquea selección de texto */
    -webkit-user-select: none; /* Para compatibilidad Safari iOS */
  }
  .icon-send {
    position: relative;
    left: 2px;
    width: 22px;
    height: 19px;
    max-width: 22px;
    max-height: 19px;
    user-select: none; /* Bloquea selección de texto */
    -webkit-user-select: none; /* Para compatibilidad Safari iOS */
  }
`;