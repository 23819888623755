import { css } from "lit";

export const styleHeader = css`
  .header-container {
    padding: 20px; /* Espaciado interno */
    display: flex; /* Habilita el modelo de caja flexible */
    align-items: center; /* Alinea elementos verticalmente al centro */
    justify-content: start; /* Alinea elementos horizontalmente al inicio */
    position: relative; /* Necesario si se posicionarán elementos hijos con valores absolutos */
    width: 100%; /* Ancho por defecto: ocupa todo el espacio disponible */
    height: 100%; /* Altura fija */
    gap: 15px; /* Espaciado entre elementos flexibles */
    background: var(
      --Azul_chatbot,
      #1446ff
    ); /* Fondo con variable o valor de color */
    box-sizing: border-box;
    user-select: none; /* Bloquea selección de texto */
    -webkit-user-select: none; /* Para compatibilidad Safari iOS */
  }

  .header-title {
    margin: 0px;
    color: var(--Fondo_sidebar, #fafafa);
    font-family: Montserrat;
    font-size: 20.23px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .header-title-span {
    font-weight: 700;
  }

  .header-sub-title {
    color: var(--Fondo_sidebar, #fafafa);
    margin: 0px;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  .container-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2.64px;
  }

  .icon-container {
    width: 78px;
    height: 78px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e7e7e7; /* Fondo gris para el ícono */
    border-radius: 50%; /* Hacerlo circular */
  }

  /* Posición del botón para cerrar el chat (ArrowDown) */
  .header-button-position {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  /* Botón de refresh, lo puedes reubicar o ajustar según tus necesidades */
  .header-button-refresh {
    position: absolute;
    top: 10px;
    right: 50px; /* Ajusta para que no se superponga con el arrow_down */
  }
  .geni-title-img {
    width: 180px; /* Ajusta el tamaño que desees */
    height: auto; /* Mantiene proporción */
    object-fit: contain; /* O cover, si fuera un background-like */
  }
`;
