import { LitElement, html } from "lit";
import { stylesMessage } from "./index.styles.js";
import clipboard from "../../../../assets/clipboard.svg"; // Ajusta la ruta de tu ícono
import arrowOut from "../../../../assets/arrow_point_out.svg"
import { unsafeHTML } from "lit-html/directives/unsafe-html.js";
import "./image/index.js";

class Message extends LitElement {
  static properties = {
    images: { type: String},
    message: { type: String },
    userType: { type: String },
    loading: { type: Boolean },
    showCopied: { type: Boolean, state: true },
    imageFocus: { type: Boolean, state: true }
  };

  static styles = stylesMessage;

  constructor() {
    super();
    this.image = "";
    this.message = "";
    this.userType = "";
    this.loading = false;
    this.showCopied = false;
    this.imageFocus = false;
  }

  _renderMessageWithBreaks() {
    if (!this.message) return html``;
  
    // Expresión regular para detectar ![Texto](URL) y [Texto](URL)
    const markdownUrlRegex = /!?\[.*?\]\((.*?)\)/g;
    // Dividimos el mensaje considerando ambos formatos
    const separeMessageInUrl = this.message.split(markdownUrlRegex)
    const messageWithImages = separeMessageInUrl.map((part) => {        
      //filtro para saber si es una imagen
      const isImage = part?.includes("amazonaws.com")
      //filtro para saber si es un link
      const isLink = 
      part?.includes("https://")
        ? true 
        : part?.includes("http://")
        ? true 
        :false
      // Si index es impar y pasa el filtro de imagen, significa que es una URL de imagen.
      if (isImage) {
        return html`
          <div 
            @click=${()=>this.onFocusImage(part)}
            class="image-container">
            <img  
              src=${arrowOut}
              class="arrow_out_img"
              alt="ver imagen ampliada del producto"
            />
            <img 
              src=${part} 
              class="image-message"
              alt="previsualización del producto"
            />
          </div>
          `;
        } //si no es una imagen sigue el filtro de link
        if(isLink){ 
          return html`
            <a href=${part} target="_blank" rel="noopener noreferrer">${part}</a>
          `;
        }
        //transforma y permite mostrar el texto de la misma forma en la que responde el socket
        const formattedMessage = part.trim().replace(/\n/g, "<br>");
        return html`${unsafeHTML(formattedMessage)}`;
      })
      .filter(Boolean); // Filtra valores vacíos o false
  
    return html`${messageWithImages}`;
  }

  /**
   * Copia el texto al portapapeles.
   * 1) Intentamos navigator.clipboard.
   * 2) Si falla, fallback con document.execCommand('copy').
   */
  async _copyToClipboard() {
    const textToCopy = this.message;

    // 1. Verifica si existe navigator.clipboard
    if (navigator.clipboard?.writeText) {
      try {
        await navigator.clipboard.writeText(textToCopy);
        this._showCopiedTooltip();
        return;
      } catch (error) {
        console.warn("Error usando navigator.clipboard:", error);
      }
    }

    // 2. Fallback: crear un textarea oculto y usar document.execCommand('copy')
    const hiddenInput = document.createElement("textarea");
    hiddenInput.value = textToCopy;
    hiddenInput.setAttribute("readonly", "");
    hiddenInput.style.position = "absolute";
    hiddenInput.style.left = "-9999px";
    document.body.appendChild(hiddenInput);
    hiddenInput.select();

    try {
      document.execCommand("copy");
      this._showCopiedTooltip();
    } catch (error) {
      console.error("Error copiando con execCommand:", error);
    }
    document.body.removeChild(hiddenInput);
  }

  /**
   * Muestra el tooltip "Mensaje copiado" por 2s.
   */
  _showCopiedTooltip() {
    this.showCopied = true;
    setTimeout(() => {
      this.showCopied = false;
    }, 2000);
  }

  onFocusImage(imageProduct){
    this.image = imageProduct;
    this.imageFocus = true;
  }

  closeImage(){
    this.image = ""; 
    this.imageFocus = false;
  }

  render() {
    const isAssistant = this.userType === "assistant";
    // Solo mostramos el botón si userType=assistant y !loading
    const canShowCopy = isAssistant && !this.loading;
    return html`
      <div class="message-wrapper">
        <!-- Burbuja de mensaje -->
        <p class="message ${this.userType}">
          ${this.loading
            ? html`<span class="loader"></span>`
            : this._renderMessageWithBreaks()}
        </p>
        <!-- Botón y tooltip, solo si es assistant y no loading -->
        ${canShowCopy
          ? html`
              <button
                class="copy-button"
                @click=${this._copyToClipboard}
                title="Copiar texto"
              >
                <img src=${clipboard} alt="Copiar" />
              </button>

              ${this.showCopied
                ? html`<div class="copied-tooltip">Mensaje copiado</div>`
                : null}
            `
          : null}
        ${this.imageFocus
          ? html`
            <image-component
              .imageFocus=${this.imageFocus}
              .image=${this.image ? this.image : ""}
              @close-image=${this.closeImage}
            >
            </image-component>
          `: ""}
      </div>
    `;
  }
}

customElements.define("message-component", Message);
