// ModalConfirmation/index.js
import { LitElement, html } from "lit";
import { styleModalConfirmation } from "./index.styles.js";

class ModalConfirmation extends LitElement {
  static properties = {
    cancelarModal: { type: Function },
    reiniciarConversacion: { type: Function },
  };

  static styles = styleModalConfirmation;

  constructor() {
    super();
    // Funciones por defecto (sobrescribibles desde fuera)
    this.cancelarModal = () => {
      this.style.display = "none";
    };
    this.reiniciarConversacion = () => {
      this.style.display = "none";
    };
  }

  render() {
    return html`
      <section class="modal-container">
        <h2 class="modal-title">¿Seguro desea reiniciar la conversación?</h2>
        <p class="modal-subtitle">No podrá revertir esta acción.</p>

        <div class="buttons-container">
          <button class="btn-cancelar" @click="${this.cancelarModal}">
            Cancelar
          </button>
          <button class="btn-reiniciar" @click="${this.reiniciarConversacion}">
            Reiniciar
          </button>
        </div>
      </section>
    `;
  }
}

customElements.define("modal-confirmation", ModalConfirmation);
