import { LitElement, html } from "lit";
import { stylesMessage } from "./index.styles.js";

class Image extends LitElement {
  static properties = {
    image: { type: String },
    imageFocus: { type: Boolean },
  };

  static styles = stylesMessage;

  constructor() {
    super();
    this.image = "";
    this.imageFocus = false;
  }

  /**
   * Maneja el cierre cuando se hace clic fuera de la imagen.
   */
  _handleClickOutside(event) {
    if (event.target.classList.contains("image-focus")) {
      this.dispatchEvent(new CustomEvent("close-image", { bubbles: true, composed: true }));
    }
  }

  render() {
    return html`
      <div class="image-focus" @click=${this._handleClickOutside}>
        <button class="close-image" @click=${() => this.dispatchEvent(new CustomEvent("close-image", { bubbles: true, composed: true }))}>
          x
        </button>
        <img src=${this.image} @click=${(e) => e.stopPropagation()} />
      </div>
    `;
  }
}

customElements.define("image-component", Image);