import { LitElement, html } from 'lit';
import { styleIconBtn } from './index.styles.js';
import "../IconImg/index.js"

class IconBtn extends LitElement {
  static properties = {
    icon: { type: String },
    styleImg: { type: String },
    description: { type: String},
    styleBtn: { type: String},
    callback: { type: Function},
    isActive: { type: Boolean},
  };

  static styles = styleIconBtn;

  constructor() {
    super();
    this.styleImg= "";
    this.styleBtn=""
    this.icon = "";
    this.description = "";
    this.callback = async () => {};
    this.isActive = true;
  }

  render() {
    return html`
        <button 
          @click=${this.callback} 
          class=${`button-default ${this.styleBtn}`}
          aria-label=${this.description}
          title=${this.description}
          .disabled=${!this.isActive}
        >
          <icon-img-component
            .iconStyle=${this.styleImg} 
            .icon=${this.icon} 
            .description=${`Icono de ${this.description}`}
          >
          </icon-img-component>
        </button>
    `;
  }
}

customElements.define('icon-btn-component', IconBtn);
