import { css } from "lit";

export const stylesMessagesBody = css`
.message-container {
  display: flex;
  width: 100%;
  align-items: center;
  background: #FFF;
}
.messages-body-container{
  box-sizing: border-box;
  display:flex;
  flex-direction: column;
  gap: 22px;
  margin: 18px;
  max-width: 416px;
  height: auto;
  background: #FFF;
}
.assistant {
  justify-content: start;
}
.user{
  justify-content: end;
}`;
