import { css } from "lit";

export const stylesMessage = css`
.image-focus {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7); /* Fondo oscuro semitransparente */
  backdrop-filter: blur(5px); /* Efecto de desenfoque ligero */
  z-index: 1000;
}

.image-focus img {
  width: auto;
  height: auto;
  max-width: 85vw;
  max-height: 85vh;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 2px 3px 22px rgba(255, 255, 255, 0.2); /* Sombra sutil */
  opacity: 0;
  transform: scale(0.9);
  animation: fadeIn 0.3s ease-in-out forwards;
}

/* Botón de cierre mejorado */
.close-image {
  position: absolute;
  top: 20px;
  right: 20px;
  background: var(
      --Azul_chatbot,
      #1446ff
    ); ;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 8px 14px;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.close-image:hover {
  background: rgba(255, 255, 255, 0.6);
}

/* Animación de entrada */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

  @media (max-width: 768px) {
    .close-image {
      padding: 7px 12px;
    }
  }
`;