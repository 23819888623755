import { css } from "lit";

export const styleIconBtn = css`
.button-default {
  display: flex;
  justify-content: center;
  align-items: center;
  padding:0;
  background-color: transparent;
  cursor: pointer;
  border: 0;
}
.button-default:hover {
  border: 0; /* Quita el borde del botón */
  background-color: transparent; /* H
}

.button-close{
  width: 30px;
  height: 30px;  
}
`