import { css, unsafeCSS } from "lit";

export const stylesFooter = css`
  .container-footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    user-select: none; /* Bloquea selección de texto */
    -webkit-user-select: none; /* Para compatibilidad Safari iOS */
  }
  .text-footer {
    color: var(--Gray-4, #bdbdbd);
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 111%;
    letter-spacing: 0.2px;
  }
  .container-right {
    display: flex;
    align-items: center;
  }
`;