import { LitElement, html } from 'lit';
import { styleIconImg } from './index.styles.js';

class IconImg extends LitElement {
  static properties = {
    icon: { type: String },
    description: { type: String },
    iconStyle: { type: String },
  };

  static styles = styleIconImg;

  constructor() {
    super();
    this.icon = "";
    this.description = "";
    this.iconStyle = "";
  }

  render() {
    return html`
        <img 
          class=${this.iconStyle} 
          src=${this.icon} 
          alt="${this.description}" 
        />
    `;
  }
}

customElements.define('icon-img-component', IconImg);
