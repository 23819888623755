import { css } from "lit";

export const styleIconImg = css`
.sm{
  width: 19px;
  height: 18px;
  flex-shrink: 0;
  margin-top: 5px;
}
.default{
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
.md{
  width: 80px;
  height: 19px;
}

.profile{
  width: 51px;
  height: 40px;
}
`