// hi-fenix-chatbot.js
import { LitElement, html, css } from "lit";
import "./layout/index.js";
import "./components/mainchat/main-chat-layout.js";
import "./components/header/index.js";
import "./components/footer/index.js";
import { getOrCreateConversationId, initializeSocket, resetConversationId,} from "./api/socket.config.js";
import "./components/floatingButton/index.js";

// Inyectar fuentes globales
const injectGlobalFonts = () => {
  if (
    !document.querySelector(
      "link[href='https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&family=Manrope:wght@500&display=swap']"
    )
  ) {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href =
      "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&family=Manrope:wght@500&display=swap";
    document.head.appendChild(link);
  }
};

injectGlobalFonts();

class HiFenixChatbot extends LitElement {
  static styles = css`
    :host {
      display: block;
      z-index: 9997;
    }

    .hidden {
      display: none;
    }
  `;

  static properties = {
    companyToken: { type: String },
    companyName: { type: String },
    autoOpen: { type: String },
    messages: { type: Array, state: true },
    isOpen: { type: Boolean },
    idConversation: { type: Boolean, state: true },
    isMobile: { type: Boolean, state: true },
    origin: { type: String, state: true},
    messageAtBot: {type: Object, state: true }
  };

  constructor() {
    super();
    this.companyToken = "";
    this.origin = "web";
    this.companyName = "";
    this.autoOpen = "true";
    this.messages = [];
    this.isOpen = false;
    this.idConversation = 1;
    this.isMobile = window.innerWidth <= 480;
    this.pendingTimeout = null; // Para timeout de 60s 
    this.messageAtBot = {
      init: "hola, muy buenas, saludame de la mejor forma, no modifiques tu forma de responder, ignorar el resto del texto -invisible: true",
      reinit: "quiero que me respondas este mensaje con una bienvenida de vuelta y con un continuamos en donde lo dejamos, no modifiques tu froma de responder, ignorar el resto del texto -invisible: true",
    }

    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth <= 480;
    });
  }

  // 1) Se ejecuta cuando el componente se monta en DOM
  connectedCallback() {
    super.connectedCallback();
    // Obtener conversationId (reutilizar si no caducó)
    this.idConversation = getOrCreateConversationId();
  }

  firstUpdated() {
    // 👉 NO ejecutar el autoOpen si esta en false
    if (!this._isAutoOpen()) {
      this._autoOpenChat();
    }
  }

  updated(changedProps) {
    super.updated(changedProps);
    if (changedProps.has("isOpen")) {
      if (this.isOpen) {
        // Cuando se abra el chat
        this._connectSocket();
      } else {
        // Cuando se cierre el chat
        this._disconnectSocket();
      }
    }
  }

  // 🔒 función auxiliar para detectar si la empresa es "cloudy"
  _isAutoOpen() {
    return this.autoOpen?.toLowerCase() === "false";
  }

  _sendMessageInit(recibedMessage) {
    // 👉 NO enviar mensajes si es Cloudy
    if (this._isAutoOpen()) return;

    this.messages?.length === 0
      ? this.sendMessage(this.messageAtBot.init, true)
      : this._hasWelcomeBackMsg(recibedMessage)
      ? this.sendMessage(this.messageAtBot.reinit, true)
      : null;
  }
  //funcion para abrir el chat automaticamente.
  _autoOpenChat(){
    setTimeout(() => {
      if (!this.isOpen) {
        this.isOpen = true;
      }
    }, 7000);
  }
//conecta al socket
  _connectSocket() {
    // Evita reconectar si ya hay un socket activo
    if (window.socket && window.socket?.connected) {
      console.log("El socket ya está conectado, no se reconectará.");
      return;
    }

    if (!this.companyToken) {
      console.error("No se puede conectar el socket: falta companyToken");
      return;
    }
    // 1) Inicializa el socket
    initializeSocket(this.companyToken);
    console.log("Socket conectado");

    // 2) Configura listeners
    window.socket.on("connect", this._onSocketConnect);
    window.socket.on("response", this._onSocketResponse);
    window.socket.on("conversation", this._onSocketConversation);
    window.socket.on("error", this._onSocketError);
  }
  _disconnectSocket() {
    console.log("Desconectando socket...");
    if (window.socket) {
      // Limpia listeners para evitar duplicados cuando se reconecte
      window.socket.off("connect", this._onSocketConnect);
      window.socket.off("response", this._onSocketResponse);
      window.socket.off("conversation", this._onSocketConversation);
      window.socket.off("error", this._onSocketError);

      // Cierra la conexión
      if (window.socket?.connected) {
        window.socket.disconnect();
      }
      window.socket = null;
    }
  }

  //*** Definimos callbacks como propiedades de la clase para poder hacer off()*
  _onSocketConnect = () => {
    console.log("Conexión establecida con el servidor.");
    if (window.socket && window.socket.connected) {
      window.socket.emit("reconnect", {
        companyToken: this.companyToken,
        conversationId: this.idConversation,
        origin: this.origin,
      });
    } else {
      console.warn(
        "No se pudo emitir el evento de reconexión: socket no disponible."
      );
    }
  };

  _onSocketResponse = (data) => {
    clearTimeout(this.pendingTimeout);
    this.pendingTimeout = null;
    const botResponse = {
      msg: data.message,
      urlImage: data.urlImage || null,
    };
    const messagesLoading = this._getAllLoadingMessages().length;
    if (messagesLoading === 1) this._cleanFirstLoadingMessage();
    this._addBotMessage(botResponse);
  };

  _onSocketConversation = (data) => {
    // Trae la conversacion existente en la db, si es que existe.
    const responseMessages = data?.conversation?.messages || [];
    const messagesFiltered = this._transformConversation(responseMessages);
    messagesFiltered?.shift();
    this.messages = messagesFiltered;
    //mensaje de entrada automatica, se le envia la respuesta completa de la conversacion para realizar filtros
    this._sendMessageInit(responseMessages);
    console.log("Servidor reconectado con exito");
  };
  //busque un |-invisble: true|en el mensaje para ocultarlo.
  _hasInvisibleMsg(text) {
    return /-invisible:\s*true/.test(text);
  }
  //verifica que se pueda devolver un mensaje de continuamos.
  _hasWelcomeBackMsg(messages = []) {
    const lengthMessages = messages.length;
    const message = messages[lengthMessages - 2];
    const isContinue =
      !(message?.content === this.messageAtBot.reinit) && lengthMessages > 2;
    return isContinue;
  }
  //transforma lo que devuelve la db para que la web lo pueda interpretar y guardar
  _transformConversation(conversation) {
    return conversation
      ?.filter((el) => el?.content && !this._hasInvisibleMsg(el?.content)) // Filtra mensajes invisibles y vacíos
      .map((el) => ({
        userType: el?.role,
        text: el?.content,
      }));
  }
  _onSocketError = (error) => {
    console.error("Error en el socket:", error?.message);
    const messagesLoading = this._getAllLoadingMessages().length;
    if (messagesLoading === 1) this._cleanFirstLoadingMessage();
    this._addBotMessage({ msg: `Ha ocurrido un error: ${error.message}` });
  };

  // 2) Función para reiniciar la conversación manualmente
  resetConversation() {
    this.messages = [];
    const newId = resetConversationId(this.companyToken);
    this.idConversation = newId;
  }

  // 3) Agregar un mensaje del bot al array
  _addBotMessage({ msg, loading = false, urlImage }) {
    this.messages = [
      ...this.messages,
      {
        text: msg,
        userType: "assistant",
        loading,
        ...(urlImage ? { images: urlImage } : {}),
      },
    ];
  }

  // Borrar el primer mensaje que esté en "loading"
  _cleanFirstLoadingMessage() {
    const index = this.messages.findIndex((msg) => msg.loading);
    if (index !== -1) {
      this.messages.splice(index, 1);
      this.messages = [...this.messages];
    }
  }

  _getAllLoadingMessages() {
    return this.messages.filter((msg) => msg.loading);
  }

  // 4) Enviar mensaje del usuario - invisible y visible
  async sendMessage(userMessage, invisibleMessage = false) {
    const messagesLoading = this._getAllLoadingMessages().length;
    if (messagesLoading) {
      this._cleanFirstLoadingMessage();
    }

    // Agrego mensaje user + "Cargando..." para la respuesta
    invisibleMessage
      ? (this.messages = [
          ...this.messages,
          { text: "Cargando...", userType: "assistant", loading: true },
        ])
      : (this.messages = [
          ...this.messages,
          { text: userMessage, userType: "user", loading: false },
          { text: "Cargando...", userType: "assistant", loading: true },
        ]);

    const messagePayload = {
      message: userMessage,
      companyToken: this.companyToken,
      conversationId: this.idConversation,
      origin: this.origin,
    };

    if (window.socket && window.socket.connected) {
      window.socket.emit("message", messagePayload);

      // Timeout de 60s
      this.pendingTimeout = setTimeout(() => {
        const loadCount = this._getAllLoadingMessages().length;
        if (loadCount > 0) {
          this._cleanFirstLoadingMessage();
          this._addBotMessage({
            msg:
              "Estamos teniendo dificultades para responder. " +
              "Por favor, inténtalo más tarde.",
          });
        }
      }, 60000);
    } else {
      console.error("El socket no está conectado.");
      this._cleanFirstLoadingMessage();
      this._addBotMessage({
        msg: "No se pudo enviar el mensaje porque el socket está desconectado.",
      });
    }
  }

  _toggleChat() {
    this.isOpen = !this.isOpen;
  }

  render() {
    return html`
      ${this.isOpen
        ? html`
            <layout-component>
              <header-component
                slot="header"
                .closeChat=${() => (this.isOpen = false)}
                .onResetChat=${() => this.resetConversation()}
                .companyName=${this.companyName}
              ></header-component>

              <main-chat-layout
                slot="main-chat"
                .messages=${this.messages}
                .onSendMessage=${this.sendMessage.bind(this)}
              ></main-chat-layout>

              <footer-component slot="footer"></footer-component>
            </layout-component>
          `
        : null}

      <floating-button
        class="${this.isOpen ? "hidden" : ""}"
        .toggleChat=${this._toggleChat.bind(this)}
        .companyName=${this.companyName}
      ></floating-button>
    `;
  }
}

customElements.define("hifenix-chatbot", HiFenixChatbot);
