import { LitElement, html, css } from "lit";
import chatbot from "../../assets/chatbot.svg";
// Importar también tu ícono Geni_logo
import GeniLogo from "../../assets/Geni_logo.svg";


// Convertir GeniLogo en URL absoluta si estamos en el CDN
const geniLogoUrl = "https://cdn.jsdelivr.net/npm/hifenix-chatbot@1.0.11/dist/assets/genilogo.svg";

class FloatingButton extends LitElement {
  static styles = css`
    .floating-button {
      position: fixed;
      bottom: 86px;
      right: 64px;
      width: 50px;
      height: 50px;
      background-color: var(--Azul_chatbot, #1446ff);
      border: none;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      z-index: 9998;
      transition: opacity 0.3s ease, transform 0.3s ease;
      user-select: none;
      -webkit-user-select: none;
    }

    .floating-button img {
      width: 30px;
      height: 24px;
    }

    @media screen and (max-width: 480px) {
      .floating-button {
        bottom: 74px;
        right: 18px;
        z-index: 9998;
      }
    }
  `;

  static properties = {
    toggleChat: { type: Function },
    companyName: { type: String }, // Nueva propiedad
  };

  constructor() {
    super();
    this.toggleChat = () => {};
    this.companyName = ""; // Por defecto vacío
  }

  render() {
    // 1) Verificar si la prop es "geni"
    const isGeni = this.companyName.toLowerCase() === "geni";

    // 2) Elegir el ícono
    const iconToUse = isGeni ? geniLogoUrl : chatbot;

    return html`
      <button class="floating-button" @click=${this.toggleChat}>
        <img src=${iconToUse} alt="Abrir chat" />
      </button>
    `;
  }
}

customElements.define("floating-button", FloatingButton);
